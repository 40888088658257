<template>
  <en-drawer :model-value="modelValue" title="活动订单详情" direction="btt" @close="$emit('update:model-value', false)" size="80%">
    <en-form :model="form.data" :loading="form.loading" class="grid grid-cols-4 gap-x-6">
      <en-form-item label="订单日期">
        <en-input :model-value="formatDate(form.data.datetime, true)" disabled></en-input>
      </en-form-item>
      <en-form-item label="订单编号">
        <en-input :model-value="form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="下单人">
        <en-input :model-value="form.data.wechatUser?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="支付方式">
        <en-input :model-value="form.data.source?.message" disabled></en-input>
      </en-form-item>
      <en-form-item label="客户名称">
        <en-input :model-value="form.data.customer?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="销售人员">
        <en-input :model-value="form.data.salesman?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="车牌号码">
        <select-maintain
          v-model="form.data.plateNo"
          v-if="form.data.source?.code === 'OFFLINE'"
          :options="form.data.customer?.ownedVehicles"
          :props="{ label: 'plateNo', value: 'plateNo' }"
          class="w-full"
        ></select-maintain>
        <en-input v-else v-model="form.data.plateNo"></en-input>
      </en-form-item>
      <en-form-item label="手机号码">
        <en-input v-model="form.data.phoneNo"></en-input>
      </en-form-item>
      <en-form-item label="订单有效期">
        <en-date-picker
          :start="form.data.mallCombo?.mallGroupPurchase?.startDate"
          :end="form.data.mallCombo?.mallGroupPurchase?.endDate"
          type="daterange"
          disabled
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="活动编号">
        <en-input :model-value="form.data.groupPurchaseOrder?.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="活动名称">
        <en-input :model-value="form.data.mallCombo?.mallGroupPurchase?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="服务次数">
        <en-input :model-value="form.data.serveTimes" disabled></en-input>
      </en-form-item>
      <en-form-item label="开团价格">
        <en-input :model-value="formatMoney(form.data.mallCombo?.sellPrice)" disabled></en-input>
      </en-form-item>
      <en-form-item label="类型">
        <en-input :model-value="form.data.type?.message" disabled></en-input>
      </en-form-item>
      <en-form-item label="订单价格">
        <en-input :model-value="formatMoney(form.data.amount)" disabled></en-input>
      </en-form-item>
      <en-form-item label="允许退款">
        <en-input :model-value="form.data.mallCombo?.mallGroupPurchase?.refundable?.message" disabled></en-input>
      </en-form-item>
      <en-form-item label="退款扣除">
        <en-input :model-value="formatPercent(form.data.mallCombo?.mallGroupPurchase?.deduction)" disabled></en-input>
      </en-form-item>
      <en-form-item label="拼团状态">
        <en-input :model-value="form.data.groupPurchaseOrder?.status?.message" disabled></en-input>
      </en-form-item>
      <en-form-item label="该团ID">
        <en-input :model-value="form.data.groupPurchaseOrder?.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="退款原因">
        <en-input :model-value="form.data.reason?.map((item: any) => item.message).join(',')" disabled></en-input>
      </en-form-item>
      <en-form-item label="退款金额">
        <en-input :model-value="formatMoney(form.data.refundAmount)" disabled></en-input>
      </en-form-item>
      <en-form-item label="预约时间">
        <en-input :model-value="form.data.mallGroupPurchaseReservation?.reservation" disabled></en-input>
      </en-form-item>
    </en-form>

    <en-table :data="form.data.mallCouponInstances">
      <en-table-column label="验证码" prop="serialNo"></en-table-column>
      <en-table-column label="卡券名称" prop="mallCoupon.name"></en-table-column>
      <en-table-column label="卡券类型" prop="mallCoupon.type.message"></en-table-column>
      <en-table-column label="满减金额" prop="mallCoupon.lowerLimitAmount"></en-table-column>
      <en-table-column label="折扣">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponInstanceDto'] }">
          {{ row.mallCoupon?.type?.discountMethod.code === 'P' ? row.mallCoupon.discountValue : 0 }}
        </template>
      </en-table-column>
      <en-table-column label="抵扣金额">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponInstanceDto'] }">
          {{ row.mallCoupon?.type?.discountMethod.code === 'F' ? row.mallCoupon.discountValue : 0 }}
        </template>
      </en-table-column>
      <en-table-column label="项目" prop="mallCoupon.maintenance.name"></en-table-column>
      <en-table-column label="配件" prop="mallCoupon.goods.name"></en-table-column>
      <en-table-column label="状态" prop="status.message"></en-table-column>
      <en-table-column label="核验时间" prop="checkedDatetime">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponInstanceDto'] }"> {{ formatDate(row.checkedDatetime) }} </template>
      </en-table-column>
      <en-table-column label="核验人" prop="checkedBy.name"></en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudMallDefinitions['MallComboOrderDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible && this.data?.id) {
          this.form.data.id = this.data.id
          this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo/order/:mallComboOrderId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/mall/combo/order',
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/mall/combo/order',
            params(params) {
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form[this.form.data.id ? 'update' : 'submit']()
              this.footer.cancel.click()
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
